import React, { Component } from 'react'
import Translate from '../costra/Translate'
import Formatter from '../costra/Formatter.js'

import CaseInfo from './CaseInfo'

import './navPanel.css'

class CasesList extends Component {

  render() {
    var filteredCases = (this.props.cases || []).filter((item, index) =>
      this.props.casesFilter === "all" ||
        !item.caseRecord.processing.finished ||
        this.props.caseIndex === index
    )
    var values = {}
    filteredCases.forEach(item => {
      if (item.caseRecord.processing.payments) {
        item.caseRecord.processing.payments.forEach(value => {
          if (values[value.currency]) {
            values[value.currency].missing += value.missing
            values[value.currency].payed += value.payed
            values[value.currency].planned += value.planned 
          }  
          else {
            values[value.currency] = {...value}
          }
        })
      }
    })
    var currencies = Object.keys(values)
    return <>
      <h3>
        {Translate.get('nav.cases.title')}
        {currencies && currencies.length > 0 && " (" + currencies.map(currency => 
          Formatter.formatAmount(values[currency].missing) + " / " + 
            Formatter.formatAmount(values[currency].planned) + " " + currency
        ).join(", ") + ")"}  
      </h3>
      <div>
        {filteredCases.map(item =>
          <CaseInfo caseRecord={item}
                    key={item.caseRecord._id.$oid}
                    id={this.props.id}/>
        )}
        {filteredCases.length < 1 && (this.props.casesFilter === "all" ?
          <i>{Translate.get('nav.activeCases.allCases.noCases')}</i>
          :
          <i>{Translate.get('nav.activeCases.activeCases.noCases')}</i>
        )}
      </div>
    </>
  }
}

export default CasesList